




import { MetaInfo } from 'vue-meta'
import { Component, Mixins, Watch } from 'vue-property-decorator'

import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import DictionaryModule from '@/store/modules/dictionary'
import MentorEducationModule from '@/store/modules/mentor/education'
import SystemModule from '@/store/modules/system'
import AuthModule from '@/store/modules/auth'
// import { RoleType } from '@/store/types'

@Component
export default class Manager extends Mixins(MasterGroupMixin, NotifyMixin, PermissionsMixin) {
  private isReady = false

  private created () {
    SystemModule.setInterface('manager')

    if (this.hasPermission(this.Permission.USERS_MANAGER_LIST)) {
      DictionaryModule.fetchRoles().catch(this.notifyError)
    }

    // Нужно знать заранее в какие мастер-группы доступны наставнику.
    // Сразу же выставляется активный и используется как значение в селектах мастер-групп и на дашборде
    // self: true - слать НЕЛЬЗЯ! Метод должен возвращать не только мои группы, но группы моих поднаставников
    MentorEducationModule.fetchMasterGroups()
      .finally(() => {
        this.isReady = true
        this.redirectByPermissions()
      })
  }

  private get self () {
    return AuthModule.self
  }

  private redirectByPermissions () {
    if (this.$route.name === 'manager') {
      if (this.masterGroups.length > 0) {
        this.$router.replace({ name: 'manager.dashboard' })
      } else if (this.hasPermission(this.Permission.COURSE_VIEW)) {
        this.$router.replace({ name: 'manager.control.courses' })
      } else if (this.hasPermission(this.Permission.MG_VIEW_ALL)) {
        this.$router.replace({ name: 'manager.control.groups' })
      } else if (this.hasSomePermissions([this.Permission.ORDERS_VIEW, this.Permission.ORDERS_APPOINT_SELF])) {
        this.$router.replace({ name: 'manager.commerce.orders' })
      } else if (this.hasPermission(this.Permission.ORDERS_PROMO_CREATE)) {
        this.$router.replace({ name: 'manager.commerce.promocodes' })
      } else if (this.hasPermission(this.Permission.ORDERS_LINK_LIST)) {
        this.$router.replace({ name: 'manager.commerce.links' })
      } else if (this.hasPermission(this.Permission.DEPOSITORY_MATERIALS_VIEW)) {
        this.$router.replace({ name: 'manager.bank.depository.courses' })
      } else if (this.hasPermission(this.Permission.DEPOSITORY_MANUAL_VIEW)) {
        this.$router.replace({ name: 'manager.bank.depository.manual' })
      } else if (this.hasSomePermissions([this.Permission.BANK_BASE_VIEW, this.Permission.BANK_PERSONAL_VIEW])) {
        this.$router.replace({ name: 'manager.bank.exercises' })
      } else if (this.hasSomePermissions([this.Permission.USERS_MASTER_LIST, this.Permission.USERS_MASTER_SEARCH])) {
        this.$router.replace({ name: 'manager.users.masters' })
      } else if (this.hasPermission(this.Permission.ROLES)) {
        this.$router.replace({ name: 'manager.users.roles' })
      } else if (this.hasSomePermissions([this.Permission.USERS_MANAGER_LIST, this.Permission.USERS_MANAGER_SEARCH])) {
        this.$router.replace({ name: 'manager.users.employees' })
      } else {
        this.$router.replace({ name: 'profile' })
      }
    }
  }

  @Watch('$route')
  private watchRoute () {
    this.redirectByPermissions()
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Менеджер',
    }
  }
}
